var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f593925bd4ecbfc0e5011ecfcf91f42659a78f0b"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import config from 'src/config';
const { sentryDSN, sentryENV } = config;

if (sentryDSN) {
  Sentry.init({
    dsn: sentryDSN,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps

    //if console: true, Sentry handles the console.log and other print out
    //if console: false, DevTools (Chrome) or the Firefox equivalent handles it.
    //it's better to keep it false for local deployments so we can set breakpoints and such

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    // @maxfieldegaj: I don't see why we would set this to 100% in development...
    // from the Sentry docs, https://docs.sentry.io/platforms/javascript/session-replay/configuration/
    // The sample rate for replays that begin recording immediately and last the entirety of the user's session. 1.0 collects all replays, and 0 collects none.
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.Integrations.Breadcrumbs({
        console: true,
      }),
      new Sentry.Replay(),
    ],
    environment: sentryENV,
    ignoreErrors: [/data will be lost if you leave this page/i], // intentional error thrown from <RouteChangeCatchDialog />
  });
}
